import React, { useContext, useEffect, useState, useCallback } from "react"
import Image from "next/image"
import s from "./Modal.module.scss"
import { IoClose } from "react-icons/io5"
import { AiOutlineQuestionCircle } from "react-icons/ai"
import { AnimatePresence, motion } from "framer-motion"
import { getWeeklyBonuses, itemNames } from "../../../utils/enum/constants"
import PointsVotes from "./points-votes"
import { ModalContext } from "../../contexts/ModalContext"
import { useHapeEquippedTraits } from "../../../services/fashion-index/inventoryServices"

const DataView = ({
	card,
	hapeTraits,
	equippedEquipment,
	isHapeOrNot,
	votes,
	isMobile,
	startScore,
	endScore
}) => {
	const [allTraits, setAllTraits] = useState([])
	const excludeShowingCategories = ["Birthday", "Heart Number"]
	const { hapeColor } = useContext(ModalContext)
	const [hasBonus, setHasBonus] = useState(false)
	const [hasBonus2, setHasBonus2] = useState(false)
	useEffect(() => {
		setHasBonus(card.bonuses?.find(b => b.cycle === 1) ? true : false)
		setHasBonus2(card.bonuses?.find(b => b.cycle === 2) ? true : false)
	}, [])
	useEffect(() => {
		const badge = card?.equipment?.badge
		const wristband = card?.equipment?.wristband
		if (isHapeOrNot) {
			const badgeTrait = {
				category: "Badge",
				value: badge?.itemType,
				rarity_type: itemNames.find(i => i.blockchainName === badge?.itemType)
					?.rarityType
			}

			const wristbandTrait = {
				category: "Wristband",
				value: wristband?.itemType,
				rarity_type: itemNames.find(
					i => i.blockchainName === wristband?.itemType
				)?.rarityType
			}

			const extra = []
			if (badge.tokenId) {
				extra.push(badgeTrait)
			}

			if (wristband.tokenId) {
				extra.push(wristbandTrait)
			}

			setAllTraits([...extra, ...card.traits])
		}
	}, [isHapeOrNot, JSON.stringify(card), JSON.stringify(card.traits)])

	useEffect(() => {
		if (equippedEquipment) {
			const badge = equippedEquipment.badges.equipped.find(
				e => e.equippedOnHapePrime === card.token_id
			)
			const wristband = equippedEquipment.wristbands.equipped.find(
				e => e.equippedOnHapePrime === card.token_id
			)

			const badgeTrait = {
				category: "Badge",
				value: badge?.itemType,
				rarity_type: itemNames.find(i => i.blockchainName === badge?.itemType)
					?.rarityType
			}

			const wristbandTrait = {
				category: "Wristband",
				value: wristband?.itemType,
				rarity_type: itemNames.find(
					i => i.blockchainName === wristband?.itemType
				)?.rarityType
			}

			const extra = []
			if (badge) {
				extra.push(badgeTrait)
			}

			if (wristband) {
				extra.push(wristbandTrait)
			}

			setAllTraits([...extra, ...allTraits])
		}
	}, [equippedEquipment, JSON.stringify(allTraits)])

	const colors = [
		{
			type: "common",
			color: "#F6F6F6"
		},
		{
			type: "uncommon",
			color: "#54B14E"
		},
		{
			type: "rare",
			color: "#2A8CD2"
		},
		{
			type: "epic",
			color: "#A152C6"
		},
		{
			type: "legendary",
			color: "#E4B145"
		},
		{
			type: "mythic",
			color: "#F43116"
		}
	]
	const getRarityTypeColor = rarityType => {
		// return colors.find(c => c.type === rarityType)?.color
		return "#FFFFFF"
	}

	const alterations = [
		{
			traitValue: "Handmade",
			rarityType: "mythic"
		},
		{
			traitValue: "Duotone",
			rarityType: "rare"
		},
		{
			traitValue: "Implants",
			rarityType: "legendary"
		}
	]

	const TOP_100_WEEK_1 = "Top 100 - Week 1"
	const TOP_100_WEEK_2 = "Top 100 - Week 2"

	const getBonus = () => {
		const bonus = getWeeklyBonuses(card.bonuses[0].rank)
		const totalPoints =
			bonus.bonusPoints + (bonus.bonusPercentage / 100) * card.clothed_rarity
		return `+ ${Math.round(totalPoints)} pts  ${
			bonus.bonusPercentage > 0
				? `(${bonus.bonusPoints} + ${bonus.bonusPercentage}%)`
				: ""
		}`
	}

	const getBonus2 = () => {
		const bonus = getWeeklyBonuses(card.bonuses[1].rank)
		const totalPoints =
			bonus.bonusPoints + (bonus.bonusPercentage / 100) * card.clothed_rarity
		return `+ ${Math.round(totalPoints)} pts  ${
			bonus.bonusPercentage > 0
				? `(${bonus.bonusPoints} + ${bonus.bonusPercentage}%)`
				: ""
		}`
	}

	const [top100WeekOne, setTop100WeekOne] = useState(TOP_100_WEEK_1)
	const [top100WeekTwo, setTop100WeekTwo] = useState(TOP_100_WEEK_2)

	const [weekOneText, toggleWeekOneText] = useState(false)
	const [weekTwoText, toggleWeekTwoText] = useState(false)

	useEffect(() => {
		setAllTraits(hapeTraits)
	}, [JSON.stringify(hapeTraits)])

	const { data: equippedTraits } = useHapeEquippedTraits()

	return (
		<>
			<div className={`${s.modal__data_only_traits}`}>
				{isMobile && !isHapeOrNot && (
					<motion.div
						initial={{ opacity: 0 }}
						animate={{ opacity: 1 }}
						exit={{ opacity: 0 }}
						className={`${s.modal__data_trait_container} ${s.modal__top}`}
						style={{ backgroundColor: hapeColor }}
					>
						<PointsVotes
							startScore={startScore}
							endScore={endScore}
							votes={votes}
						/>
						{(hasBonus || hasBonus2) && (
							<div className={s.top_100_container}>
								{hasBonus && (
									<div
										className={s.top_100_week_1}
										onClick={() => {
											toggleWeekOneText(prev => !prev)
											if (weekOneText) {
												setTop100WeekOne(getBonus())
											} else {
												setTop100WeekOne(TOP_100_WEEK_1)
											}
										}}
									>
										{top100WeekOne}
									</div>
								)}
								{hasBonus2 && (
									<div
										className={`${s.top_100_week_1} ${s.top_100_week_2}`}
										onClick={() => {
											toggleWeekTwoText(prev => !prev)
											if (weekTwoText) {
												setTop100WeekTwo(getBonus2())
											} else {
												setTop100WeekTwo(TOP_100_WEEK_2)
											}
										}}
									>
										{top100WeekTwo}
									</div>
								)}
							</div>
						)}
					</motion.div>
				)}
				<AnimatePresence>
					{!isMobile && (hasBonus || hasBonus2) && (
						<div className={s.top_100_container}>
							{hasBonus && (
								<div
									className={s.top_100_week_1}
									onMouseEnter={() => {
										setTop100WeekOne(getBonus())
									}}
									onMouseLeave={() => {
										setTop100WeekOne(TOP_100_WEEK_1)
									}}
								>
									{top100WeekOne}
								</div>
							)}
							{hasBonus2 && (
								<div
									className={`${s.top_100_week_1} ${s.top_100_week_2}`}
									onMouseEnter={() => {
										setTop100WeekTwo(getBonus2())
									}}
									onMouseLeave={() => {
										setTop100WeekTwo(TOP_100_WEEK_2)
									}}
								>
									{top100WeekTwo}
								</div>
							)}
						</div>
					)}
					{equippedTraits &&
						equippedTraits.map((trait, i) => {
							return (
								!excludeShowingCategories.includes(trait.category) && (
									<motion.div
										initial={{ opacity: 0 }}
										animate={{ opacity: 1 }}
										exit={{ opacity: 0 }}
										key={trait.category + " " + trait.value}
										className={`${s.modal__data_trait_container} ${
											!isMobile && i === 0 ? s.modal__top : ""
										}`}
									>
										<div className={`${s.modal__trait_title}`}>
											<div className={`${s.modal__icon_category}`}>
												<Image
													src={`/static/img/white/${trait.category}.svg`}
													priority={true}
													width={26}
													height={25}
													quality={100}
													alt={trait.category}
													draggable="false"
												></Image>
											</div>
											<div className={`${s.modal__trait_category}`}>
												<div className={`${s.modal__trait_category_text}`}>
													{trait.category}
												</div>
												<div className={`${s.modal__trait_value_text}`}>
													{trait.value}
												</div>
											</div>
											<div
												className={`${s.modal__rarity_type}`}
												style={{
													color: getRarityTypeColor(
														trait.value === "Handmade"
															? "legendary"
															: trait.rarity_type
													)
												}}
											>
												{alterations.find(a => a.traitValue === trait.value)
													?.rarityType ?? trait.rarity_type}
											</div>
										</div>
									</motion.div>
								)
							)
						})}
				</AnimatePresence>
			</div>
		</>
	)
}
export default DataView
