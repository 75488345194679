import { atom, useAtom, useSetAtom } from "jotai"
import { useCallback, useContext, useEffect } from "react"
import { ModalContext } from "../../src/contexts/ModalContext"
import { checkCny2024Traits } from "../../utils/dynamic-equipment/helpers"
import { deviceTypeAtom } from "./DeviceState"

export const leftExtraAtom = atom<string>("")
export const rightExtraAtom = atom<string>("")
export const layeredExtraAtom = atom<string>("")
export const isCny24LanternsEnhancedBackgroundActivatedAtom =
	atom<boolean>(false)
export const windowTypeAtom = atom("desktop")

export const useResetModalLayersState = () => {
	const setLeftExtra = useSetAtom(leftExtraAtom)
	const setRightExtra = useSetAtom(rightExtraAtom)
	const setLayeredExtra = useSetAtom(layeredExtraAtom)
	const setIsCny24LanternsEnhancedBackgroundActivated = useSetAtom(
		isCny24LanternsEnhancedBackgroundActivatedAtom,
	)
	const resetModalState = () => {
		setLeftExtra("") // Reset to initial state or whatever is appropriate
		setRightExtra("")
		setLayeredExtra("")
		setIsCny24LanternsEnhancedBackgroundActivated(false) // Reset to initial state
		// Reset any other relevant state here
	}
	return resetModalState
}

function determineStateUpdates(traits, deactivateBackgrounds, windowType) {
	let leftExtra = ""
	let rightExtra = ""
	let layeredExtra = ""
	let isCny24LanternsEnhancedBackgroundActivated = false
	const isMobile = windowType === "Mobile"
	const isTablet = windowType === "Tablet"

	if (deactivateBackgrounds) {
		rightExtra = null
		layeredExtra = null
		isCny24LanternsEnhancedBackgroundActivated = false
	} else {
		const hasCny24Lanterns = traits.some(
			(t) => t.value.toLowerCase() === "cny24 lanterns (fortune red)",
		)
		isCny24LanternsEnhancedBackgroundActivated = hasCny24Lanterns

		if (hasCny24Lanterns) {
			rightExtra = null
			layeredExtra = null
		} else {
			const visorWithLaser = traits.some((t) => t.value === "Visor with Laser")
			const enhancedBackgroundBananas = traits.some(
				(t) =>
					t.category.toLowerCase() === "enhanced background" &&
					t.value.toLowerCase() === "bananas",
			)

			if (enhancedBackgroundBananas) {
				layeredExtra = "bananas_layered_extra.png"
				rightExtra = "bananas_right_extra.png"
			}

			if (visorWithLaser) {
				rightExtra = "laser.png"
			} else if (enhancedBackgroundBananas) {
				layeredExtra = "bananas_layered_extra.png"
				rightExtra = "bananas_right_extra.png"

				const prefix = isMobile ? "mobile" : isTablet ? "tablet" : ""
				console.log("prefix", windowType)
				if (isMobile) {
					layeredExtra = `bananas_layered_extra.png`
					rightExtra = ""
				} else if (isTablet) {
					leftExtra = "bananas_left_extra_tablet.png"
				}
			}
		}
	}

	return {
		leftExtra,
		rightExtra,
		layeredExtra,
		isCny24LanternsEnhancedBackgroundActivated,
	}
}

export const useUpdateModalCardLayers = () => {
	const {
		setModalColorsAsCny2024,
		setModalColorsAsNative,
		hapeColor,
		hapeModalGradient,
	} = useContext(ModalContext)

	const [leftExtra, setLeftExtra] = useAtom(leftExtraAtom)
	const [rightExtra, setRightExtra] = useAtom(rightExtraAtom)
	const [layeredExtra, setLayeredExtra] = useAtom(layeredExtraAtom)
	const [
		isCny24LanternsEnhancedBackgroundActivated,
		setIsCny24LanternsEnhancedBackgroundActivated,
	] = useAtom(isCny24LanternsEnhancedBackgroundActivatedAtom)
	const [windowType] = useAtom(windowTypeAtom)
	const [deviceType] = useAtom(deviceTypeAtom)

	const updateStateWithNewTraits = useCallback(
		(traitsForLayering, deactivateBackgrounds, hapeId) => {
			const {
				leftExtra,
				rightExtra,
				layeredExtra,
				isCny24LanternsEnhancedBackgroundActivated,
			} = determineStateUpdates(
				traitsForLayering,
				deactivateBackgrounds,
				deviceType,
			)
			const legacyCard = {
				token_id: hapeId,
				traits: traitsForLayering,
			}
			console.log("legacyCard", legacyCard)

			checkCny2024Traits(traitsForLayering, setModalColorsAsCny2024, () =>
				setModalColorsAsNative(legacyCard),
			)

			// Use the values to update your state
			setLeftExtra(leftExtra)
			setRightExtra(rightExtra)
			setLayeredExtra(layeredExtra)
			setIsCny24LanternsEnhancedBackgroundActivated(
				isCny24LanternsEnhancedBackgroundActivated,
			)
		},
		[
			leftExtra,
			rightExtra,
			layeredExtra,
			isCny24LanternsEnhancedBackgroundActivated,
		],
	)

	return updateStateWithNewTraits
}
