import s from "./Modal.module.scss"
import CountUp from "react-countup"
import { useGetHapeStats } from "../../../services/fashion-index/inventoryServices"

export default function PointsVotes({ startScore, endScore, votes }) {
	const dimensions = 15

	const { data: stats } = useGetHapeStats()

	const getRatio = () => {
		if (votes) {
			if (votes.winningVotes === 0) {
				return 0
			} else {
				const winningVotes = votes.winningVotes
				const totalVotes = votes.totalVotes
				return Math.round((winningVotes / totalVotes) * 100)
			}
		}
	}

	return (
		<div className={s.points_votes_container}>
			<div>
				<div>Fashion Score</div>
				<div className={s.value}>
					<CountUp start={0} end={stats?.fashion_score} delay={0}>
						{({ countUpRef }) => (
							<div>
								<span ref={countUpRef} />
							</div>
						)}
					</CountUp>
				</div>
			</div>
			<div>
				<div>Votes</div>
				<div className={s.value}>
					{votes ? (
						<span>{votes?.winningVotes}</span>
					) : (
						<span className={s.modal__blur}>00</span>
					)}
				</div>
			</div>
			<div>
				<div>Vote Ratio</div>
				<div className={s.value}>
					{votes ? (
						<span>{getRatio()}%</span>
					) : (
						<span className={s.modal__blur}>00%</span>
					)}
				</div>
			</div>
			<div>
				<div>Recent Votes</div>
				<div className={s.value}>
					{votes ? (
						<LastFiveVotes lastFiveVotes={votes?.lastFiveVotes} />
					) : (
						<span className={s.modal__blur}>x</span>
					)}
				</div>
			</div>
		</div>
	)
}

const LastFiveVotes = ({ lastFiveVotes }) => {
	const dimensions = 15

	return (
		<div className={s.vote_heart_container}>
			{lastFiveVotes?.length > 0
				? lastFiveVotes.map((vote, i) => {
						return (
							<span
								key={i}
								className={`${
									i !== lastFiveVotes.length - 1 ? s.vote_heart_right : ""
								} ${s.vote_heart}`}
							>
								<img
									src={`/static/img/hapeornot/${vote}.svg`}
									width={dimensions}
									height={dimensions}
									alt="heart"
								/>
							</span>
						)
					})
				: "N/A"}
		</div>
	)
}
