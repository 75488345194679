import { davenatorApi, fashionIndexApi } from "../../utils/fashion-index/api"
import {
	QueryClient,
	QueryFunctionContext,
	QueryKey,
	useMutation,
	useQuery,
	useQueryClient,
	UseQueryResult,
} from "@tanstack/react-query"
import axios, { AxiosResponse, CancelToken } from "axios"
import { atomWithMutation, atomWithQuery } from "jotai-tanstack-query"
import { atom, useAtom, useSetAtom } from "jotai"
import {
	CategorizedInventory,
	CheckOwnerRequest,
	EquipTraitRequestParams,
	ResetHapeRequestParams,
	SyncUserAndGetHapeInventoryRequestParams,
	TraitItem,
	UnequipTraitRequestParams,
} from "../../src/types/fashionIndex/InventoryTypes"
import { useContext, useEffect, useRef, useState } from "react"
import { processDynamicTraitsForDavenator } from "../../utils/dynamic-equipment/helpers"
import { ModalContext } from "../../src/contexts/ModalContext"
import { useUpdateModalCardLayers } from "../../store/global/ModalCardOverlayState"
import { TabName } from "../../src/types/fashionIndex/HapeModalTypes"
import { debounce } from "lodash"
import { isWeb3AuthenticatedAtom } from "./AuthServices"
import { toast } from "react-hot-toast"

export const walletAddressAtom = atom<string>("")
export const hapeIdAtom = atom<string>("")
export const inventoryAtom = atom<CategorizedInventory>({})
export const hasUpdatedHapeAtom = atom<boolean>(false)
export const modalActiveTabAtom = atom<TabName>(TabName.traits)
export const hoverTraitAtom = atom<TraitItem | string>("")
export const activeCategoryAtom = atom<string>("")
export const canEquipAtom = atom<boolean>(true)
export const isFirstGetInventoryFetchForThisHape = atom<boolean>(true)

export const useResetInventoryState = () => {
	const setModalTab = useSetAtom(modalActiveTabAtom)
	const setHasUpdatedHape = useSetAtom(hasUpdatedHapeAtom)
	const setHapeId = useSetAtom(hapeIdAtom)
	const setInventory = useSetAtom(inventoryAtom)
	const setIsFirstGetInventoryFetchForThisHape = useSetAtom(
		isFirstGetInventoryFetchForThisHape,
	)
	const resetInventoryState = () => {
		setModalTab(TabName.traits)
		setHasUpdatedHape(false)
		setHapeId("")
		setInventory({})
		setIsFirstGetInventoryFetchForThisHape(true)
	}

	return resetInventoryState
}
// Function signature with TypeScript for fetching inventory
async function fetchInventoryForHape(
	walletAddress: string,
	hapeId: string,
	isSyncInventory: boolean = true,
): Promise<any> {
	const url = `get-inventory-for-this-hape/?hape_id=${hapeId}&is_sync_inventory=${isSyncInventory}`
	const response: AxiosResponse<any[]> = await fashionIndexApi.get(url)
	return response.data
}

export function useCategorizedInventory(): UseQueryResult<CategorizedInventory> {
	// Access walletAddress and hapeId from Jotai atoms
	const [walletAddress] = useAtom(walletAddressAtom)
	const [hapeId] = useAtom(hapeIdAtom)
	const [isWeb3Authenticated] = useAtom(isWeb3AuthenticatedAtom)
	const { data: isUserOwnerOfThisHape } = useCheckHapeOwner()
	const [isFirstGetInventoryFetch, setIsFirstGetInventoryFetchForThisHape] =
		useAtom(isFirstGetInventoryFetchForThisHape)
	// Use React Query to fetch and return the inventory data
	return useQuery<CategorizedInventory>({
		queryKey: ["inventoryForHape", walletAddress, hapeId],
		queryFn: async () => {
			const inventory = await fetchInventoryForHape(
				walletAddress,
				hapeId,
				isFirstGetInventoryFetch,
			)

			if (inventory) {
				setIsFirstGetInventoryFetchForThisHape(false)
			}

			return inventory
		},
		enabled:
			!!isWeb3Authenticated &&
			!!walletAddress &&
			!!hapeId &&
			!!isUserOwnerOfThisHape,
		gcTime: Infinity,
		staleTime: 0,
	})
}

export const postEquipTrait = async (
	params: EquipTraitRequestParams,
): Promise<any> => {
	// Replace `any` with the actual response type if known
	const url = `equip-trait/` // Adjust the URL as necessary
	const response: AxiosResponse<any> = await fashionIndexApi.post(url, params)
	return response.data
}

export const useEquipTrait = () => {
	const queryClient = useQueryClient()

	const [walletAddress] = useAtom(walletAddressAtom)
	const [hapeId] = useAtom(hapeIdAtom)
	const setHasUpdatedHape = useSetAtom(hasUpdatedHapeAtom)

	const inventoryQueryKey = ["inventoryForHape", walletAddress, hapeId]
	const hapeEquippedTraitsQueryKey = ["hapeEquippedTraits", hapeId]
	const davenatorImageQueryKey = ["davenatorImage", hapeId]
	const davenatorImageForCardQueryKey = ["davenatorImageForCard", hapeId]
	const hapeStatsQueryKey = ["hapeStats", hapeId]
	const getMyHapesQueryKey = ["getMyHapes", walletAddress]
	const davenatorExportImageForHapeQueryKey = [
		"davenatorImageForExport",
		hapeId,
	]
	const updateModalCardLayers = useUpdateModalCardLayers()

	const { mutate, mutateAsync } = useMutation({
		mutationFn: postEquipTrait,
		mutationKey: ["equipTraitOnHape"],
		onMutate: async (newlyEquippedItem) => {
			// --- Step 1: Perform Optimistic Update ---
			// Snapshot the previous state
			const previousItems =
				queryClient.getQueryData<CategorizedInventory>(inventoryQueryKey)

			// Update Categorized Inventory optimistically
			queryClient.setQueryData<CategorizedInventory>(
				inventoryQueryKey,
				(categorizedInventory) => {
					// Clone categorizedInventory to avoid direct mutation
					const updatedCategorizedInventory = { ...categorizedInventory }

					// Find the array of traits for the specified category
					let traitsInThisCategory: TraitItem[] =
						updatedCategorizedInventory[
							newlyEquippedItem.trait_to_equip.category
						] || []

					// Map through it to update the specified trait
					const updatedTraitsInThisCategory = traitsInThisCategory.map(
						(item) =>
							item.equip_id === newlyEquippedItem.trait_to_equip.equip_id
								? { ...item, is_equipped_on_this_hape: true }
								: { ...item, is_equipped_on_this_hape: false },
					)

					// Update the array for the specified category in the cloned object
					updatedCategorizedInventory[
						newlyEquippedItem.trait_to_equip.category
					] = updatedTraitsInThisCategory

					// Return the updated categorizedInventory
					return updatedCategorizedInventory
				},
			)

			// Fetch the latest equipped traits after mutation but before re-fetching the Davenator image
			await queryClient.invalidateQueries({
				queryKey: hapeEquippedTraitsQueryKey,
			})

			// --- Step 2: Re-generate Hape Image ---
			const currentlyEquippedTraits = queryClient.getQueryData<any>(
				hapeEquippedTraitsQueryKey,
			)

			const { trait_to_equip } = newlyEquippedItem
			let isCategoryFound = false

			// Create a new list to avoid direct mutation of the currentlyEquippedTraits
			const updatedTraits = currentlyEquippedTraits.map((trait) => {
				if (trait.category === trait_to_equip.category) {
					isCategoryFound = true
					// Replace the existing trait object with the newly equipped trait
					return trait_to_equip
				}
				return trait // Return the trait unchanged if not the matching category
			})

			if (!isCategoryFound) {
				// If the category was not found, add the new trait to the list
				updatedTraits.push(trait_to_equip)
			}

			const {
				traitsFormattedForDavenator,
				traitsForLayering,
				deactivateBackgrounds,
			} = processDynamicTraitsForDavenator(updatedTraits, hapeId)

			updateModalCardLayers(traitsForLayering, deactivateBackgrounds, hapeId)

			return { previousItems }
		},
		onError: (
			err,
			newlyEquippedItem,
			context: {
				previousItems?: CategorizedInventory
			},
		) => {
			console.log("error", err)
			// Roll back to the previous state in case of an error
			if (context?.previousItems) {
				queryClient.setQueryData<CategorizedInventory>(
					inventoryQueryKey,
					context.previousItems,
				)
			}
		},
		onSuccess: (result) => {
			setHasUpdatedHape(true)
			queryClient.invalidateQueries({
				queryKey: hapeStatsQueryKey,
			})
			queryClient.invalidateQueries({
				queryKey: hapeEquippedTraitsQueryKey,
			})
			queryClient.invalidateQueries({
				queryKey: davenatorImageQueryKey,
			})
			queryClient.invalidateQueries({
				queryKey: davenatorImageForCardQueryKey,
			})
			queryClient.invalidateQueries({
				queryKey: davenatorExportImageForHapeQueryKey,
			})
			// Update is_reset_to_native to false so that
			// .. the gallery knows to Davenate the overlay image
			// .. without having to invalidate and fetch getMyHapes
			queryClient.setQueryData<any>(getMyHapesQueryKey, (myHapes) => {
				if (myHapes) {
					const updatedMyHapes = myHapes.map((hape) => {
						if (hape.token_id === hapeId) {
							return {
								...hape,
								rank: result.new_rank,
								is_reset_to_native: false,
							}
						} else {
							return hape
						}
					})

					return updatedMyHapes
				}
			})
		},
		onSettled: () => {
			// Invalidate or refetch inventory queries to ensure data consistency
			queryClient.invalidateQueries({
				queryKey: inventoryQueryKey,
			})
		},
	})

	return { mutate, mutateAsync }
}

export const postUnequipTrait = async (
	params: UnequipTraitRequestParams,
): Promise<any> => {
	// Replace `any` with the actual response type if known
	const url = `unequip-trait/` // Adjust the URL as necessary
	const response: AxiosResponse<any> = await fashionIndexApi.post(url, params)
	return response.data
}

export const useUnequipTrait = () => {
	const queryClient = useQueryClient()

	const [walletAddress] = useAtom(walletAddressAtom)
	const [hapeId] = useAtom(hapeIdAtom)
	const setHasUpdatedHape = useSetAtom(hasUpdatedHapeAtom)

	const inventoryQueryKey = ["inventoryForHape", walletAddress, hapeId]
	const hapeEquippedTraitsQueryKey = ["hapeEquippedTraits", hapeId]
	const davenatorImageQueryKey = ["davenatorImage", hapeId]
	const hapeStatsQueryKey = ["hapeStats", hapeId]
	const davenatorImageForCardQueryKey = ["davenatorImageForCard", hapeId]
	const getMyHapesQueryKey = ["getMyHapes", walletAddress]
	const davenatorExportImageForHapeQueryKey = [
		"davenatorImageForExport",
		hapeId,
	]

	const updateModalCardLayers = useUpdateModalCardLayers()

	const { mutate, mutateAsync } = useMutation({
		mutationFn: postUnequipTrait,
		mutationKey: ["unequipTraitOnHape"],
		onMutate: async (newlyUnequippedItem) => {
			// --- Step 1: Perform Optimistic Update ---
			// Snapshot the previous state
			const previousItems =
				queryClient.getQueryData<CategorizedInventory>(inventoryQueryKey)

			//Update Categorized Inventory optimistically
			queryClient.setQueryData<CategorizedInventory>(
				inventoryQueryKey,
				(categorizedInventory) => {
					// Clone categorizedInventory to avoid direct mutation
					const updatedCategorizedInventory = { ...categorizedInventory }

					// Find the array of traits for the specified category
					let traitsInThisCategory: TraitItem[] =
						updatedCategorizedInventory[
							newlyUnequippedItem.trait_to_unequip.category
						] || []

					const shouldEquipNative =
						newlyUnequippedItem.trait_to_unequip.category === "Clothing"
					// Map through it to update the specified trait
					const updatedTraitsInThisCategory = traitsInThisCategory.map(
						(item) => {
							// Determine whether this item should be the one to have is_equipped_on_this_hape set to true
							const isNativeAndShouldEquipOrSetToUnequipped =
								shouldEquipNative && item.trait_type === "Native"

							return {
								...item,
								is_equipped_on_this_hape:
									isNativeAndShouldEquipOrSetToUnequipped,
							}
						},
					)

					// Update the array for the specified category in the cloned object
					updatedCategorizedInventory[
						newlyUnequippedItem.trait_to_unequip.category
					] = updatedTraitsInThisCategory

					// Return the updated categorizedInventory
					return updatedCategorizedInventory
				},
			)

			// Fetch the latest equipped traits after mutation but before re-fetching the Davenator image
			await queryClient.invalidateQueries({
				queryKey: hapeEquippedTraitsQueryKey,
			})

			// --- Step 2: Re-generate Hape Image ---
			const currentlyEquippedTraits = queryClient.getQueryData<any>(
				hapeEquippedTraitsQueryKey,
			)

			const { trait_to_unequip } = newlyUnequippedItem
			let isCategoryFound = false

			// Create a new list to avoid direct mutation of the currentlyEquippedTraits
			const updatedTraits = currentlyEquippedTraits.map((trait) => {
				if (trait.category === trait_to_unequip.category) {
					isCategoryFound = true
					// Replace the existing trait object with the newly equipped trait
					return trait_to_unequip
				}
				return trait // Return the trait unchanged if not the matching category
			})

			if (!isCategoryFound) {
				// If the category was not found, add the new trait to the list
				updatedTraits.push(trait_to_unequip)
			}

			const {
				traitsFormattedForDavenator,
				traitsForLayering,
				deactivateBackgrounds,
			} = processDynamicTraitsForDavenator(updatedTraits, hapeId)

			updateModalCardLayers(traitsForLayering, deactivateBackgrounds, hapeId)

			return { previousItems }
		},
		onError: (
			err,
			newlyEquippedItem,
			context: {
				previousItems?: CategorizedInventory
			},
		) => {
			// Roll back to the previous state in case of an error
			if (context?.previousItems) {
				queryClient.setQueryData<CategorizedInventory>(
					inventoryQueryKey,
					context.previousItems,
				)
			}
		},
		onSuccess: (result) => {
			setHasUpdatedHape(true)
			queryClient.invalidateQueries({
				queryKey: hapeStatsQueryKey,
			})
			queryClient.invalidateQueries({
				queryKey: hapeEquippedTraitsQueryKey,
			})
			queryClient.invalidateQueries({
				queryKey: davenatorImageQueryKey,
			})
			queryClient.invalidateQueries({
				queryKey: davenatorImageForCardQueryKey,
			})
			queryClient.invalidateQueries({
				queryKey: davenatorExportImageForHapeQueryKey,
			})
			// Update is_reset_to_native to false so that
			// .. the gallery knows to Davenate the overlay image
			// .. without having to invalidate and fetch getMyHapes
			queryClient.setQueryData<any>(getMyHapesQueryKey, (myHapes) => {
				if (myHapes) {
					const updatedMyHapes = myHapes.map((hape) => {
						if (hape.token_id === hapeId) {
							return {
								...hape,
								rank: result.new_rank,
								is_reset_to_native: false,
							}
						} else {
							return hape
						}
					})

					return updatedMyHapes
				}
			})
		},
		onSettled: () => {
			// Invalidate or refetch inventory queries to ensure data consistency
			queryClient.invalidateQueries({
				queryKey: inventoryQueryKey,
			})
		},
	})

	return { mutate, mutateAsync }
}

const resetHapeToNative = async (
	params: ResetHapeRequestParams,
): Promise<any> => {
	const url = `reset-hape-to-native/` // Adjust the URL as necessary
	const { walletAddress, hapeId } = params
	const response: AxiosResponse<any> = await fashionIndexApi.post(url, {
		wallet_address: walletAddress, // Using snake_case for the API payload
		hape_id: hapeId,
	})
	return response.data
}

export const useResetHapeToNative = () => {
	const queryClient = useQueryClient()
	const [walletAddress] = useAtom(walletAddressAtom)
	const [hapeId] = useAtom(hapeIdAtom)
	const inventoryQueryKey = ["inventoryForHape", walletAddress, hapeId]
	const hapeEquippedTraitsQueryKey = ["hapeEquippedTraits", hapeId]
	const davenatorImageQueryKey = ["davenatorImage", hapeId]
	const davenatorImageForCardQueryKey = ["davenatorImageForCard", hapeId]
	const hapeStatsQueryKey = ["hapeStats", hapeId]
	const getMyHapesQueryKey = ["getMyHapes", walletAddress]
	const davenatorExportImageForHapeQueryKey = [
		"davenatorImageForExport",
		hapeId,
	]
	return useMutation({
		mutationFn: async () => {
			const data = await resetHapeToNative({ walletAddress, hapeId })
			queryClient.setQueryData<any>(getMyHapesQueryKey, (myHapes) => {
				const updatedMyHapes = myHapes.map((hape) => {
					if (hape.token_id === hapeId) {
						return { ...hape, is_reset_to_native: false }
					} else {
						return hape
					}
				})

				return updatedMyHapes
			})
			return data
		},
		onSuccess: () => {
			queryClient.invalidateQueries({
				queryKey: hapeStatsQueryKey,
			})
			queryClient.invalidateQueries({
				queryKey: hapeEquippedTraitsQueryKey,
			})
			queryClient.invalidateQueries({
				queryKey: davenatorImageQueryKey,
			})
			queryClient.invalidateQueries({
				queryKey: davenatorImageForCardQueryKey,
			})
			queryClient.invalidateQueries({
				queryKey: davenatorExportImageForHapeQueryKey,
			})
			queryClient.invalidateQueries({
				queryKey: inventoryQueryKey,
			})
		},
	})
}

const fetchHapeEquippedTraits = async (hapeId: string): Promise<any> => {
	const url = `get-hape-equipped-traits/${hapeId}/`
	const response: AxiosResponse<any[]> = await fashionIndexApi.get(url)
	return response.data
}

export function useHapeEquippedTraits(): UseQueryResult<TraitItem[]> {
	// Access walletAddress and hapeId from Jotai atoms
	const [hapeId] = useAtom(hapeIdAtom)

	// Use React Query to fetch and return the inventory data
	return useQuery<TraitItem[]>({
		queryKey: ["hapeEquippedTraits", hapeId],
		queryFn: () => fetchHapeEquippedTraits(hapeId),
		enabled: !!hapeId,
		gcTime: Infinity,
	})
}

const fetchGetHapeStats = async (hapeId: string): Promise<any> => {
	const url = `get-hape-stats/${hapeId}/`
	const response: AxiosResponse<any[]> = await fashionIndexApi.get(url)
	return response.data
}

export function useGetHapeStats(): UseQueryResult<any> {
	// Access walletAddress and hapeId from Jotai atoms
	const [hapeId] = useAtom(hapeIdAtom)
	return useQuery<TraitItem[]>({
		queryKey: ["hapeStats", hapeId],
		queryFn: () => fetchGetHapeStats(hapeId),
		enabled: !!hapeId,
		gcTime: Infinity,
	})
}

export const fetchGenerateDavenator = async (
	davenatorTraits: any[],
): Promise<any> => {
	const response: AxiosResponse<any> = await davenatorApi.post(
		"",
		{
			fmt: "png",
			attributes: davenatorTraits,
		},
		{
			headers: {
				"Content-Type": "application/json",
			},
		},
	)
	const base64Image = `data:image.+;base64,${response.data["image"]}`
	return base64Image
}

export function useDavenator(): UseQueryResult<any> {
	const queryClient = useQueryClient()
	const [hapeId] = useAtom(hapeIdAtom)
	const { data: hapeEquippedTraits, isSuccess } = useHapeEquippedTraits()
	const updateModalCardLayers = useUpdateModalCardLayers()

	return useQuery({
		queryKey: ["davenatorImage", hapeId],
		queryFn: async () => {
			// Ensure we always get the latest traits at the time of fetching.
			const latestHapeEquippedTraits = await queryClient.fetchQuery({
				queryKey: ["hapeEquippedTraits", hapeId],
				queryFn: () => fetchHapeEquippedTraits(hapeId),
			})

			if (latestHapeEquippedTraits) {
				const {
					traitsFormattedForDavenator,
					traitsForLayering,
					deactivateBackgrounds,
				} = processDynamicTraitsForDavenator(latestHapeEquippedTraits, hapeId)
				updateModalCardLayers(traitsForLayering, deactivateBackgrounds, hapeId)

				const img = await fetchGenerateDavenator(traitsFormattedForDavenator)
				return img
			}
		},
		enabled: !!hapeId && isSuccess,
		gcTime: Infinity,
		refetchOnWindowFocus: false,
	})
}

const syncUserAndGetHapeInventory = async (
	params: SyncUserAndGetHapeInventoryRequestParams,
): Promise<any> => {
	const url = `sync-user-and-get-hape-inventory/` // Adjusted URL, no longer appending the wallet address in the path
	const { userWalletAddress, hapeId } = params
	const response: AxiosResponse<any[]> = await fashionIndexApi.post(url, {
		wallet_address: userWalletAddress,
		hape_id: hapeId,
	})
	return response.data
}

export const useSyncUserAndGetHapeInventory = () => {
	const queryClient = useQueryClient()
	const [userWalletAddress] = useAtom(walletAddressAtom)
	const [hapeId] = useAtom(hapeIdAtom)
	const { mutate } = useMutation({
		mutationFn: () =>
			syncUserAndGetHapeInventory({ userWalletAddress, hapeId }),
		mutationKey: ["syncUserAndGetHapeInventory"],
		onSuccess: (data) => {
			// Handle successful mutation, data is the resolved value from syncUser
			console.log("User synced successfully:", data)
			const inventoryQueryKey = ["inventoryForHape", userWalletAddress, hapeId]
			queryClient.setQueryData(inventoryQueryKey, data)
		},
		onError: (error) => {
			// Handle error case
			console.error("Error syncing user:", error)
		},
	})
	return { mutate }
}

const fetchCheckHapeOwner = async (
	proposed_owner_address: string,
	hape_id: string,
): Promise<boolean> => {
	const url = `check-hape-owner/?proposed_owner_address=${proposed_owner_address}&hape_id=${hape_id}`
	try {
		const response: AxiosResponse<any> = await fashionIndexApi.get(url)
		return response.data.is_owner
	} catch (error) {}
}

export function useCheckHapeOwner(): UseQueryResult<boolean> {
	// Access walletAddress and hapeId from Jotai atoms
	const [walletAddress] = useAtom(walletAddressAtom)
	const [hapeId] = useAtom(hapeIdAtom)
	return useQuery<boolean>({
		queryKey: ["checkHapeOwner", walletAddress, hapeId],
		queryFn: () => fetchCheckHapeOwner(walletAddress, hapeId),
		enabled: !!hapeId && !!walletAddress,
		gcTime: Infinity,
	})
}

export function useGetDavenatorExportImageForDownload(): UseQueryResult<any> {
	const queryClient = useQueryClient()
	const [hapeId] = useAtom(hapeIdAtom)
	const hapeEquippedTraitsQueryKey = ["hapeEquippedTraits", hapeId]

	return useQuery<any>({
		queryKey: ["davenatorImageForExport", hapeId],
		queryFn: async () => {
			const currentlyEquippedTraits = await queryClient.fetchQuery({
				queryKey: ["hapeEquippedTraits", hapeId],
				queryFn: () => fetchHapeEquippedTraits(hapeId),
			})

			const {
				traitsFormattedForDavenator,
				traitsForLayering,
				deactivateBackgrounds,
			} = processDynamicTraitsForDavenator(
				currentlyEquippedTraits,
				hapeId,
				true,
			)

			const img = await fetchGenerateDavenator(traitsFormattedForDavenator)
			return img
		},
		enabled: !!hapeId,
		gcTime: Infinity,
	})
}
